import { computed } from 'vue'

import { unrefs } from 'public/src/pages/components/FilterBar/utils/index'
import { getStickyConfigZIndex } from './utils'
import useHeaderHeight from 'public/src/pages/components/FilterBar/hooks/useHeaderHeight.js'

// 是否配置navbar吸顶
export function useIsConfigSticky ({ filterBar, picTopNav, dynamicStickyConfig }) {
  return computed(() => {
    return dynamicStickyConfig.value.hasOwnProperty('isConfigSticky')
      ? dynamicStickyConfig.value.isConfigSticky
      : Boolean(
        filterBar.value?.filterConfig?.isStickyFilter
        || (filterBar.value?.filterConfig?.isStickyPicToNav && picTopNav.length === 0)
      )
  })
  
}

function getStickyConfigByPicks ({ filterBar, headerHeight, isConfigSticky }) {
  const { isStickyPicksPic, isStickySearch } = filterBar?.filterConfig
  let top = isStickyPicksPic ? 43 : headerHeight
  const height = 36
  top = top + (isStickySearch ? headerHeight : 0)
  return {
    top,
    rootMarginTop: top + height,
    translageY: isStickySearch ? 0 : headerHeight, // 下滑偏移量
    zIndex: getStickyConfigZIndex(isConfigSticky, isStickySearch),
    stickyHeight: height,
  }
}

function getStickyConfigTop ({ isStickySearch, headerHeight }) {
  // if (!isSticky) return -1000
  
  return isStickySearch ? headerHeight : 0
}

export function useStickyConfig ({ catInfo, filterBar, isConfigSticky, dynamicStickyConfig }) {
  const headerHeight = useHeaderHeight()

  return computed(() => {
    // if (!isConfigSticky.value) return {} // 非navBar吸顶
    if (catInfo.value?.type === 'picks') return getStickyConfigByPicks(unrefs({ filterBar, isConfigSticky })) // picks图文特殊处理
    const isStickySearch = filterBar.value?.filterConfig?.isStickySearch
    const { top: dynamicTop = 0 } = dynamicStickyConfig.value || {}
    const top = dynamicTop || getStickyConfigTop(unrefs({ isConfigSticky, isStickySearch, headerHeight }))
    const height = catInfo.value?.pageName === 'page_store' ? 26 : 36

    return {
      top,
      zIndex: getStickyConfigZIndex(isConfigSticky.value, isStickySearch),
      rootMarginTop: top + height,
      translageY: isStickySearch ? 0 : headerHeight.value, // 下滑偏移量
      stickyHeight: height,
    }
  })
}
