
// 用document.body.clientWidth在低端机存在性能问题
const calcPageWidth = () => {
  if (typeof window === 'undefined') {
    return 390
  }
  const fontSize = Number.parseFloat(document.querySelector('html').style.fontSize)
  return fontSize * 10
}

// 计算每行放多少个元素
export const calcRowLength = () => {
  if (typeof window === 'undefined') {
    return 5
  }
  const pageWidth = calcPageWidth()
  if (pageWidth < 280) {
    return 3
  }
  if (pageWidth < 360) {
    return 4
  }
  if (pageWidth < 434) {
    return 5
  }
  if (pageWidth < 522) {
    return 6
  }
  if (pageWidth < 612) {
    return 7
  }
  return 8
}

// 计算每个元素的padding
export const calcItemPadding = (params) => {

  const { isCardTab = false } = params || {}

  if (typeof window === 'undefined') {
    return 16
  }
  const pageWidth = calcPageWidth()
  const count = calcRowLength()
  const padding = isCardTab ? 12 : 24 // @flag-pic-padding 改之前搜一下这个标记 // [卡片模式用6px，非卡片模式12px]
  const cardPadding = isCardTab ? 6 + 6 : 0 // 卡片布局的左右间距
  return (pageWidth - padding - cardPadding - 64 * count) / (count - 1)
}

// ''表示不展示，FirstScreen第一页2行，SecondScreen第二页2行
export const getTwoLineStyle = ({ listAbtResult, catInfo }) => {
  const abtResult = listAbtResult.value
  if (catInfo.value.type === 'search') {
    const SearchNavigationTwoLine = abtResult.SearchNavigationTwoLine?.p?.SearchNavigationTwoLine
    return SearchNavigationTwoLine
  }
  const ListNavigationTwoLine = abtResult.ListNavigationTwoLine?.p?.ListNavigationTwoLine
  return ListNavigationTwoLine
}

export const isSlideExpandShow = ({ isStickyStatus, list, twoLineStyle }) => {
  if (isStickyStatus.value) {
    return false
  }
  const total = list.length
  return twoLineStyle.value ?
    (twoLineStyle.value === 'FirstScreen' ? total >= 8 : total >= 13) :
    false
}

export const showTwoLineFromEntry = ({
  catInfo,
  listAbtResult,
}) => {
  if (catInfo.value.type === 'search') {
    return true
  }
  const ListNavigationTwoLineEntrance = listAbtResult.value.ListNavigationTwoLine?.p?.ListNavigationTwoLineEntrance
  if (ListNavigationTwoLineEntrance === 'sbc') {
    if (catInfo.value.entranceType === 'sbc') {
      return true
    }
    return false
  } else if (ListNavigationTwoLineEntrance === 'category') {
    if (catInfo.value.fromPageType === 'category') {
      return true
    }
    return false
  }
  return true
}
