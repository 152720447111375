<template>
  <div 
    :class="{
      'ptn-picks__container': true,
      'ptn-picks__container-small': isStickyPicksPic && isSmallStatus,
      'ptn-picks__container-pic': isStickyPicksPic,
      'ptn-picks__container-text': !isStickyPicksPic,
    }"
  >
    <component
      :is="curComponent"
      :list="formatList"
      :reverse="locals.GB_cssRight"
      :is-small-status="isSmallStatus"
      :is-show-icon="false"
      :space-between="6"
      :shape="PIC_TOP_NAV_SHAPE.CIRCLE"
      :active-value="activeValue"
      @change="onChange" 
    />
    <div
      v-show="formatList.length && !isSmallStatus && isStickyPicksPic"
      class="pictn-bottom"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LayoutPicks',
})
</script>

<script setup>
import { inject, computed } from 'vue'

import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import PicNavList from '../PicNavList.vue'
import TextNavList from '../TextNavList.vue'

// const PicNavList = defineAsyncComponent(() => import('../PicNavList.vue'))
// const TextNavList = defineAsyncComponent(() => import('../TextNavList.vue'))
const emits = defineEmits(['change'])
const list = inject('list', {})
const locals = inject('locals', {})
const filterBar = inject('filterBar', {})
const queryParams = inject('queryParams', {})
const isSmallStatus = inject('isSmallStatus', false)

const isStickyPicksPic = computed(() => filterBar.value?.filterConfig?.isStickyPicksPic)
const activeValue = computed(() => queryParams.value?.navId || list.value?.[0]?.id || '')

const formatList = computed(() => {
  return list.value.map((item, index) => {
    const obj = { value: item.id, label: item.title }
    if (isStickyPicksPic.value) {
      obj.src = item?.image?.src
      obj.isLazyLoad = index > 5
      obj.lazyImg = locals.value.LAZY_IMG
    } 

    return obj
  }) 
})

const curComponent = computed(() => {
  return isStickyPicksPic.value
    ? PicNavList
    :  TextNavList
})

const onChange = index => emits('change', list.value[index]?.id)
</script>

<style scoped lang="less">
.ptn-picks {
  &__container {
    background: #fff;
  }

  &__container-pic {
    padding: 8px 0 12px 12px;
    margin-bottom: -10px;
    :deep(.Slist-tabbar-target > div:last-child) {
      margin-right: 12px;
    }
  }

  &__container-text {
    padding: 0 12px;
  }

  &__container-small {
    padding: 6px; 
  }
  
}
.pictn-bottom {
  width: 10rem; // document.body最大宽度是10rem
  height: 6px;
  background: #f5f5f5;
  margin-top: 8px;
  margin-left: -12px; // @flag-pic-padding 改之前搜一下这个标记
}
</style>
