<template>
  <div
    ref="refContainer"
    :class="{
      'fixed': isFixed,
      'scroll-fixed-container': true,
      'sticky': !isFixed
    }"
    :style="isFixed ? {} : zIndexStyle"
  >
    <div
      ref="refContent"
      class="content"
      :style="isFixed ? {
        ...zIndexStyle,
        top: `${stickyTop === -1 ? scrollTop : stickyTop }px`,
        transition: 'top 0.3s ease'
      } : {}"
    >
      <slot></slot>
    </div>

    <div
      class="skeleton"
      :style="{height: `${skeletonHeight || mySkeletonHeight}px`}"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { throttle } from '@shein/common-function'

export default defineComponent({
  emits: ['fixedChange'],
  props: {
    // 是否配置吸顶
    isConfigSticky: {
      type: Boolean,
      default: false
    },
    // 滚动多少开始吸顶
    scrollTop: {
      type: Number,
      default: 0
    },
    // 吸顶时候设置top值
    stickyTop: {
      type: Number,
      default: -1
    },
    zIndex: {
      type: Number,
      default: 0
    },
    // scroll容器
    scrollEl: {
      type: String,
      default: ''
    },
    // 占位dom的高度
    skeletonHeight: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      isFixed: false,
      mySkeletonHeight: 0
    }
  },
  computed: {
    zIndexStyle () {
      return {
        zIndex: this.zIndex,
        transform: `translateZ(${this.zIndex}px)`
      }
    }
  },
  watch: {
    isFixed () {
      this.$emit('fixedChange', this.isFixed)
    },
    // 改变是否需要吸顶时，确认当前吸顶状态
    isConfigSticky(val) {
      if (val) {
        throttle({
          func: this.onScroll(),
          wait: 100,
          options: { leading: false }
        })
      } else {
        this.isFixed = false
      }
    }
  },
  methods: {
    reset () { 
      this.isFixed = false
    },
    scrollIntoView () { 
      this.$refs.refContainer.scrollIntoView(true)
    },
    onScroll () {
      const domContainer = this.$refs?.refContainer
      const domContent = this.$refs?.refContent
      if (!domContent
        || !domContainer
        || (!window?.isBodyFixed && !(document.body.scrollTop || document.documentElement.scrollTop) && !this.scrollEl)
      ) {
        this.isFixed = false

        return
      }
      const rectContent  = domContent.getBoundingClientRect()
      const rectContainer  = domContainer.getBoundingClientRect()
      this.mySkeletonHeight === 0 && (this.mySkeletonHeight = rectContent.height)
      this.isFixed = !!rectContent.height && Math.floor(rectContainer.top) <= this.scrollTop
    }
  },
})
</script>

<style lang="less" scoped>
.scroll-fixed-container {
  width: 100%;
  
  .content {
    display: block;
  }

  .skeleton {
    display: none;
  }

  &.fixed {
    .content {
      width: 100%;
      position: fixed;
    }

    .skeleton {
      display: block;
    }
  }

  &.sticky {
    position: sticky;
    top: -1000px;
  }
}
</style>

