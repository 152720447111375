<template>
  <div 
    ref="viewArea"
    class="Slist-tabbar"
    :class="slideClass"
  >
    <div 
      ref="slist" 
      class="Slist-tabbar-target"
      :class="listClass" 
      :style="listStyle"
    >
      <slot></slot>
      <div
        v-if="rightSlotShow || !$slots.right"
        ref="rightSlot"
      >
        <slot 
          name="right"
        >
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { resizeObServer, DynamicSlide, findIndex } from './utils/index'

export default defineComponent({
  name: 'SSlidetab',

  provide() {
    return {
      slideTab: this,
    }
  },
  emits: ['done', 'translateXChange', 'input'],
  props: {
    modelValue: {
      type: Number,
      default: -1000,
    },

    // 近似等于超出边界时最大可拖动距离(px);
    additionalX: {
      type: Number,
      default: 200,
    },

    // 灵敏度(惯性滑动时的灵敏度,值越小，阻力越大),可近似认为速度减为零所需的时间(ms);
    sensitivity: {
      type: Number,
      default: 1000,
      validator(value) {
        return value > 0
      },
    },

    // 回弹过程duration;
    reBoundingDuration: {
      type: Number,
      default: 360,
    },

    // 惯性回弹指数(值越大，幅度越大，惯性回弹距离越长);
    reBoundExponent: {
      type: Number,
      default: 10,
      validator(value) {
        return value > 0
      },
    },

    // 选中item样式
    activeStyle: {
      type: Object,
      default: () => {},
    },

    // 是否可以反选选中
    invertSelect: {
      type: Boolean,
      default: () => false,
    },

    // 翻转
    reverse: {
      type: Boolean,
      default: () => false,
    },

    // 每个元素的间距 px
    spaceBetween: {
      type: Number,
      default: 0,
    },

    // 滚动在最后面了继续拖动距离
    doneDistance: {
      type: Number,
      default: 20, // 50px
    },

    // 是否开启动态内容，（当子元素内容的宽度会变化开启）。
    dynamicSlide: {
      type: Boolean,
      default: false,
    },

    slideClass: {
      type: String,
      default: ''
    },

    listClass: {
      type: String,
      default: ''
    },

    slideReady: {
      type: Boolean,
      default: true,
    },
    isCanSlide: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      translateX: 0,
      startX: 0,
      lastX: 0,
      currentX: 0,
      touching: false,      // 是否处于touch状态;
      reBounding: false,    // 是否处于回弹过程; 用于样式的改动
      startMoveTime: 0,
      endMoveTime: 0,
      inertiaFrame: 0,      // 标识
      speed: 0,             // 滑动速度(正常滑动时一般不会超过10);
      acceleration: 0,      // 惯性滑动加速度;
      frameStartTime: 0,
      frameEndTime: 0,
      frameTime: 16.7,      // 每个动画帧的ms数
      durationTime: 0,      // 过渡动画时间
      listOffset: [],       // 元素偏移量集合
      listElWidth: 0,       // 容器宽度
      childrens: [],        // 所有子元素集合
      isTransition: false,  // 开启过渡动画
      firstOpenPage: true,  // 第一次打开组件。  用于计算宽度
      dynamicSlider: null,  // 动态容器实例
      currentItemIndex: 0,  // 当前滑动到内容索引， 从0开始
      direction: null,      // 滑动时方向
      startY: 0,
      startInnerX: 0,
      currentY: 0,          // 当前y轴值
      viewAreaWidth: 0,     // 可视区宽度;
      rightSlotShow: false,
    }
  },

  computed: {
    listStyle() {
      const params = {
        transform: `translate3d(${this.translateX}px, 0px, 0px)`,
      }
      const transitionParams = {
        transitionTimingFunction: this.transitionTimingFunction,
        transitionDuration: `${this.durationTime || this.transitionDuration}ms`,
      }
      return !this.isTransition ? params : { ...params, ...transitionParams }
    },
    transitionDuration() {
      if (this.reBounding && !this.touching) {
        return this.reBoundingDuration // 回弹的速度
      }
      if (this.touching || (!this.reBounding && !this.touching)) {
        return 0
      }
      return this.durationTime
    },
    transitionTimingFunction() {
      return this.reBounding
        ? 'cubic-bezier(0.25, 0.46, 0.45, 0.94)'
        : 'cubic-bezier(0.1, 0.57, 0.1, 1)'
    },
    // 可视区与可滑动元素宽度差值, 最大滚动距离;
    listWidth() {
      return this.listElWidth - this.viewAreaWidth
    },
    // 是否向左惯性滚动;
    isMoveLeft() {
      return this.currentX <= this.startX
    },
    isMoveRight() {
      return this.currentX >= this.startX
    },
    // 关闭滚动
    closeSlide() {
      return this.listWidth <= 0 // 最大滚动距离小于0 不需要滑动
    },
  },

  watch: {
    async modelValue() {
      await nextTick()
      this.checkPosition()
    },

    translateX(movex) {
      this.changeDynamicIndex(movex) // todo: 防抖？？
      this.$emit('translateXChange', movex)
    },

    slideReady(ready) {
      if (ready) {
        this.init()
      }
    }
  },

  mounted() {
    this.init()
    this.bindEvent()
  },
  beforeUnmount() {
    this.removeEvent()
    this.resizeObserverInner?.disconnect(this.$refs.slist)
  },
  methods: {
    async init() {
      if (!this.slideReady) {
        return
      }
      await nextTick()
      this.initSlides()
    },

    initSlides() {
      this.resizeObserverInner = resizeObServer(this.$refs.slist, () => {
        this.updateObServer()
      })
    },

    updateObServer() {
      if (this.firstOpenPage) {
        this.childrens = [...(this.$refs.slist?.children || [])]
      }
      this.handleInnerWidth()  
      this.commonReset()        // 重置触摸事件
      this.closeTransition()    // 当前这一次取消过渡动画

      if (this.closeSlide || this.firstOpenPage) {
        this.translateX = 0
      }

      if (this.modelValue < 0) {     // 未选中值
        this.checkDynamicMovex()
      } else {
        this.checkPosition()
      }

      if (this.firstOpenPage) {
        this.firstOpenPage = false
      }
    }, 

    // 获取相关容器的宽度。
    async handleInnerWidth() {
      await nextTick()
      this.viewAreaWidth = this.$refs?.viewArea?.offsetWidth
      const listWidth = this.$refs?.slist?.getBoundingClientRect().width
      const rightSlotWidth = this.$refs.rightSlot?.offsetWidth || 0
      this.rightSlotShow = listWidth - this.viewAreaWidth - rightSlotWidth > 0
      this.listElWidth = this.rightSlotShow ? listWidth - rightSlotWidth : listWidth 
    },

    checkDynamicMovex() {
      if (!this.dynamicSlide || this.closeSlide) {
        return
      }
      if (this.firstOpenPage || !this.dynamicSlider) {
        this.dynamicSlider = new DynamicSlide(
          this.$refs.slist,
          this.spaceBetween
        )
      } else {
        this.dynamicSlider.init()
        // 计算当前需要滚动的位置
        const moveX = this.dynamicSlider.widths[this.currentItemIndex]
        const innerMoveX = Math.min(moveX, this.listWidth)
        this.translateX = this.reverse ? innerMoveX : innerMoveX * -1
      }
    },

    /* 移动时计算当前滚动值 */
    changeDynamicIndex(x) {
      if (this.dynamicSlider && this.dynamicSlider.widths.length) {
        const curX = Math.min(Math.abs(x), this.listWidth) // 防止计算超过容器的值
        this.currentItemIndex = findIndex(this.dynamicSlider.widths, curX)
      }
    },

    /* 关闭当前移动时的过渡动画 */
    async closeTransition() {
      this.isTransition = false // 过渡动画删除
      window.requestAnimationFrame(() => {
        this.isTransition = true
      })
    },

    /* 清空数据， 重新计算 在切商品列表时外部使用， 还需要在显示列表后，重新计算容器宽度 */
    updateReset() {
      this.firstOpenPage = true
      this.dynamicSlider = null
      this.resizeObserverInner.unobserve(this.$refs.slist) // 结束上个页面的监听

      nextTick(() => {
        this.$refs.slist && this.resizeObserverInner.observe(this.$refs.slist) // 开启新的监听
      })
    },

    /* 外部调用 位置复位 */ 
    resetMoveX() {
      this.closeTransition()
      if (this.modelValue >= 0) {
        this.checkPosition()
      } else {
        this.translateX = 0
      }
    },

    commonReset() {
      this.durationTime = 0
      this.touching = false
      cancelAnimationFrame(this.inertiaFrame)
    },

    getTouches(e) {
      return (
        (e.targetTouches && (e.targetTouches[0] || e.changedTouches[0])) || {}
      )
    },

    // start
    handleTouchStart(event) {
      if (!this.isCanSlide) {
        return
      }
      this.isTransition = true
      event.stopPropagation()
      cancelAnimationFrame(this.inertiaFrame)
      const { pageX, pageY } = this.getTouches(event)
      this.startY = pageY
      this.lastX = pageX
      this.startInnerX = pageX
      this.durationTime = 0
      this.direction = null
    },

    // move
    handleTouchMove(event) {
      if (!this.isCanSlide) {
        return
      }
      event.stopPropagation()
      this.startX = this.lastX
      const { pageX, pageY } = this.getTouches(event)
      this.currentX = pageX
      this.currentY = pageY
      this.getDirection()
      if (!this.isHorizontal()) return
      this.moveFollowTouch()
      this.touching = true // 触摸阶段
      this.startMoveTime = this.endMoveTime // 记录每次开始与结束时间
      this.endMoveTime = event.timeStamp // 每次触发touchmove事件的时间戳;
    },

    // end
    handleTouchEnd(event) {
      if (!this.isCanSlide) {
        return
      }
      this.touching = false
      this.handleDoneDistance()

      if (this.closeSlide) {
        this.reBounding = true // 回弹过渡
        this.translateX = 0
        return
      }
      
      if (this.checkReboundX()) {
        // 检测是否已经滚动到两端
        cancelAnimationFrame(this.inertiaFrame)
        return
      }
      let silenceTime = event.timeStamp - this.endMoveTime // 触摸持续时间
      if (silenceTime > 100) return // 停顿时间超过100ms不产生惯性滑动;
      let timeStamp = this.endMoveTime - this.startMoveTime // 快速过程时间
      timeStamp = timeStamp > 0 ? timeStamp : 8
      this.speed = (this.lastX - this.startX) / timeStamp // 初滑动速度
      this.acceleration = this.speed / this.sensitivity // 惯性滑动加速度;
      this.frameStartTime = new Date().getTime() // 记录开始时间戳
      this.inertiaFrame = requestAnimationFrame(this.moveByInertia)
    },

    getDirection() {
      const LOCK_DIRECTION_DISTANCE = 8 // 锁定方向距离,  在都小于10距离时，判断谁的值大来进行设置方向
      const x = Math.abs(this.currentX - this.startInnerX)
      const y = Math.abs(this.currentY - this.startY)
      if (
        !this.direction ||
        (x < LOCK_DIRECTION_DISTANCE && y < LOCK_DIRECTION_DISTANCE)
      ) {
        if (x > y) {
          this.direction = 'horizontal' // 水平方向
        }
        if (y > x) {
          this.direction = 'vertical'
        }
      }
    },

    isHorizontal() {
      return this.direction === 'horizontal'
    },

    // 如果需要回弹则进行回弹操作并返回true;
    checkReboundX() {
      this.reBounding = false
      const innerReboundX = () => {
        if (this.translateX + this.listWidth < 0) {
          // 左边复位
          this.reBounding = true // 回弹过程
          this.translateX = -this.listWidth
          return true
        }

        if (this.translateX > 0) {
          // 右边滑动复位
          this.reBounding = true // 回弹过程
          this.translateX = 0
          return true
        }
        return false
      }
      const reverseReboundX = () => {
        if (this.translateX - this.listWidth > 0) {
          // 往右回弹
          this.reBounding = true // 回弹过程
          this.translateX = this.listWidth
          return true
        }
        if (this.translateX < 0) {
          this.reBounding = true // 回弹过程
          this.translateX = 0
          return true
        }
        return false
      }
      return this.reverse ? reverseReboundX() : innerReboundX()
    },

    handleDoneDistance() {
      const directionName = ['last', 'next']
      const moveX = this.reverse ? this.translateX * -1 : this.translateX
      const flag = this.listWidth <= 0 ?
        moveX + this.doneDistance < 0 :
        moveX + this.listWidth + this.doneDistance < 0
      if (flag) {
        this.$emit('done', directionName[1])
      } else if (moveX - this.doneDistance > 0) {
        this.$emit('done', directionName[0])
      }
    },

    bindEvent() {
      this.$el.addEventListener('touchstart', this.handleTouchStart, false)
      this.$el.addEventListener('touchmove', this.handleTouchMove, false)
      this.$el.addEventListener('touchend', this.handleTouchEnd, false)
    },

    removeEvent() {
      this.$el.removeEventListener('touchstart', this.handleTouchStart)
      this.$el.removeEventListener('touchmove', this.handleTouchMove)
      this.$el.removeEventListener('touchend', this.handleTouchEnd)
    },

    // touch拖动
    moveFollowTouch() {
      const curX = this.translateX + this.listWidth
      const moveX = this.currentX - this.lastX // 每次移动距离

      const leftMove = () => {
        if ((this.translateX <= 0 && curX > 0) || this.translateX > 0) {
          this.translateX += moveX // 每次移动的距离
        } else if (curX <= 0) {
          const outX = Math.abs(this.translateX + this.listWidth) // 超出边界的值
          this.translateX +=
            (this.additionalX * moveX) / (this.viewAreaWidth + outX)
        }
      }

      const rightMove = () => {
        if (this.translateX >= 0) {
          const outX = this.viewAreaWidth + this.translateX
          this.translateX += (this.additionalX * moveX) / outX
        } else if ((this.translateX <= 0 && curX >= 0) || curX <= 0) {
          this.translateX += moveX // 每次移动的距离
        }
      }

      const leftMoveReverse = () => {
        if (this.translateX <= 0) {
          // 超出边界后进行拖动
          const outX = Math.abs(this.translateX) // 超出边界的值
          this.translateX +=
            (this.additionalX * moveX) / (this.viewAreaWidth + outX)
        } else {
          this.translateX += moveX // 每次移动的距离
        }
      }

      const rightMoveReverse = () => {
        if (this.listWidth - this.translateX >= 0) {
          this.translateX += moveX // 每次移动的距离
        } else if (this.translateX - this.listWidth > 0) {
          const outX = this.translateX - this.listWidth
          this.translateX +=
            (this.additionalX * moveX) / (this.viewAreaWidth + outX)
        }
      }

      if (this.isMoveLeft) {
        // 向左拖动
        if (this.reverse) {
          leftMoveReverse()
        } else {
          leftMove()
        }
      } else {
        // 向右拖动
        if (this.reverse) {
          rightMoveReverse()
        } else {
          rightMove()
        }
      }
      this.lastX = this.currentX
    },

    // 惯性滑动
    moveByInertia() {
      this.frameEndTime = new Date().getTime()
      this.frameTime = this.frameEndTime - this.frameStartTime

      const rightSpeed = () => {
        if (this.translateX >= 0) {
          this.acceleration *=
            (this.reBoundExponent + this.translateX) / this.reBoundExponent
          this.speed = Math.max(this.speed - this.acceleration, 0)
        } else {
          this.speed = Math.max(
            this.speed - this.acceleration * this.frameTime,
            0
          )
        }
      }
      const leftSpeed = () => {
        if (this.translateX <= -this.listWidth) {
          // 超出边界的过程;
          // 加速度指数变化;
          this.acceleration *=
            (this.reBoundExponent +
              Math.abs(this.translateX + this.listWidth)) /
            this.reBoundExponent
          this.speed = Math.min(this.speed - this.acceleration, 0) // 为避免减速过程过短，此处加速度没有乘上frameTime;
        } else {
          this.speed = Math.min(
            this.speed - this.acceleration * this.frameTime,
            0
          ) // 随每次加载速度越来越小
        }
      }

      const rightSpeedReverse = () => {
        if (this.translateX - this.listWidth < 0) {
          this.speed = Math.max(
            this.speed - this.acceleration * this.frameTime,
            0
          )
        } else {
          // 超出边界
          this.acceleration *=
            (this.reBoundExponent + (this.translateX - this.listWidth)) /
            this.reBoundExponent
          this.speed = Math.max(this.speed - this.acceleration, 0)
        }
      }
      const leftSpeedReverse = () => {
        if (this.translateX > 0) {
          this.speed = Math.min(
            this.speed - this.acceleration * this.frameTime,
            0
          )
        } else {
          // 超出边界
          this.acceleration *=
            (this.reBoundExponent - this.translateX) / this.reBoundExponent
          this.speed = Math.min(this.speed - this.acceleration, 0)
        }
      }

      if (this.isMoveLeft) {
        // 向左惯性滑动;
        this.reverse ? leftSpeedReverse() : leftSpeed()
      } else {
        this.reverse ? rightSpeedReverse() : rightSpeed()
      }

      this.translateX += (this.speed * this.frameTime) / 2
      if (Math.abs(this.speed) <= 0.001) {
        cancelAnimationFrame(this.inertiaFrame)
        this.checkReboundX() // 结束递归, 超过边界后 执行回弹
        return
      }

      this.frameStartTime = this.frameEndTime // 当前时间给下一次开始
      this.inertiaFrame = requestAnimationFrame(this.moveByInertia) // 递归加速
    },

    // 点击切换item时，调整位置使当前item尽可能往中间显示
    checkPosition() {
      const { childrens, modelValue, translateX, listWidth, viewAreaWidth, closeSlide } = this
      if (modelValue < 0 || childrens.length <= modelValue || closeSlide) return
      this.reBounding = true
      this.durationTime = 500

      const activeItem = childrens[modelValue]
      const { offsetWidth, offsetLeft } = activeItem
      const half = (viewAreaWidth - offsetWidth) / 2
      const absTransX = Math.abs(translateX)
      let changeX = 0

      const innerChangeX = () => {
        // 思路： 当前元素offsetLeft -  移动位置 - （视图 - 当前宽度) /2 = 当前元素是应该向左还是右
        if (offsetLeft <= absTransX + half) {
          changeX = half - (offsetLeft + translateX)
        } else {
          changeX = -(offsetLeft - absTransX - half)
        }
      }
      const reverseChangeInnerX = () => {
        if (!this.listElWidth) {
          this.listElWidth = this.$refs.slist.getBoundingClientRect().width // 防止获取不到宽度
        }
        const offsetRightInner = this.listElWidth - offsetLeft - offsetWidth
        if (offsetRightInner - absTransX - half >= 0) {
          changeX = offsetRightInner - absTransX - half // 已经减去当前子元素宽度一半
        } else {
          changeX = -(translateX + half - offsetRightInner)
        }
      }
      !this.reverse ? innerChangeX() : reverseChangeInnerX()

      let lastX = changeX + translateX
      // 两种边界情况, 虽然end事件会处理 checkReboundX，异步更新时需要处理。
      if (!this.reverse) {
        lastX > 0 && (lastX = 0)
        lastX < -listWidth && (lastX = -listWidth)
      } else {
        lastX < 0 && (lastX = 0)
        lastX > listWidth && (lastX = listWidth)
      }
      this.translateX = lastX
    },
  },
})
</script>

<style lang="less">
.Slist-tabbar {
  display: flex;
  width: 100%;
  touch-action: pan-y;
  overflow: hidden;

  .Slist-tabbar-target {
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
  }
}
</style>
