<script lang="jsx">
import { defineComponent } from 'vue'
import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { expose } from 'public/src/pages/common/analysis/directive'

export default defineComponent({
  name: 'PicNavItem',
  directives: {
    expose,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isSmallStatus: {
      type: Boolean,
      default: false
    },
    isShowIcon: {
      type: Boolean,
      default: false
    },
    shape: {
      type: String,
      default: PIC_TOP_NAV_SHAPE.RECT
    },
    label: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    lazyImg: {
      type: String,
      default: ''
    },
    isConfigActive: {
      type: Boolean,
      default: false
    },
    isLazyLoad: {
      type: Boolean,
      default: false
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    analysisData: {
      type: Object,
      default: () => ({})
    }
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  render({ $props: props }) {
    const {
      isActive,
      isSmallStatus,
      isShowIcon,
      shape,
      label,
      src,
      lazyImg,
      isConfigActive,
      isLazyLoad,
      cssRight,
      analysisData
    } = props

    function useClassName({ prefix, extra = {} }) {
      const isActiveStatus = isConfigActive ? isActive : false
      const isCircle = shape === PIC_TOP_NAV_SHAPE.CIRCLE

      return {
        ...extra,
        [prefix]: true,
        [`${prefix}__small`]: isSmallStatus,
        [`${prefix}__active`]: isActiveStatus,
        [`${prefix}__rect`]: !isCircle && !isSmallStatus,
        [`${prefix}__rect-small`]: !isCircle && isSmallStatus,
        [`${prefix}__circle`]: isCircle && !isSmallStatus,
        [`${prefix}__circle-small`]: isCircle && isSmallStatus
      }
    }

    const imgRender = () => {
      const classImgName = useClassName({
        prefix: 'content-img',
        extra: { lazyload: isLazyLoad }
      })

      if (isLazyLoad) {
        return (
          <img
            class={classImgName}
            alt={label}
            src={lazyImg}
            data-src={src}
            data-design-width={100}
          />
        ) 
      }

      const cutImgSrc = !this?.cutImg
        ? src
        : this?.cutImg(src, 100)
      
      return (
        <img
          class={classImgName}
          alt={label}
          src={cutImgSrc}
        />
      )
    }

    const iconRender = () => {
      return (
        <div
          class={`picnav-item__icon-active ${
            cssRight ? 'picnav-item__icon-ar' : ''
          }`}
          style={{ display: isActive && isShowIcon ? 'block' : 'none' }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M0 0H16V16L7.5 7.5L0 0Z"
              fill="#000"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.3536 6.06066L13 7.70711L13.7071 7L12.0607 5.35355L13.7071 3.70711L13 3L11.3536 4.64645L9.70711 3L9 3.70711L10.6464 5.35355L9 7L9.70711 7.70711L11.3536 6.06066Z"
              fill="white"
            />
          </svg>
        </div>
      )
    }

    const classItemName = useClassName({ prefix: 'content-item' })
    const classLabelName = useClassName({ prefix: 'content-label' })

    return (
      <div class={classItemName}
        v-expose={analysisData?.expose}
      >
        {imgRender()}
        <div class={classLabelName}>
          <span>{label}</span>
        </div>
        {iconRender()}
      </div>
    )
  },
})
</script>

<style lang="less" scoped>
@heightRect: 84px;
@heightCircle: 89px;
@heightRectSmall: 39px;
@heightCircleSmall: 36px;

.content-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s;
  position: relative;

  &__small {
    width: auto;
    flex-direction: row;
  }

  &__active {
    border-radius: 2px;
    border: 1px solid var(---sui_color_gray_dark1, #000);
    background: var(---sui_color_gray_weak2, #f6f6f6);
  }
  &__active&__circle {
    border: 0px;
    img {
      border: 2px solid var(---sui_color_gray_dark1, #000);
      padding: 2px;
    }
  }
  &__active&__circle-small {
    border: 0px;
    img {
      border: 1px solid var(---sui_color_gray_dark1, #000);
      padding: 1px;
    }
  }

  &__rect {
    height: @heightRect;
    padding: 3px;
    background: #f5f5f5;
  }
  &__rect-small {
    background: #f5f5f5;
    height: @heightRectSmall;
    padding: 2px 4px 2px 2px;
  }

  &__circle {
    width: 64px;
    height: @heightCircle;
    background: #fff;
  }

  &__circle-small {
    background: #fff;
    height: @heightCircleSmall;
  }
}

.content-img {
  display: block;
  object-fit: cover;

  &__rect {
    width: 50px;
    height: 50px;
    margin-bottom: 4px;
  }
  &__rect-small {
    width: 29px;
    height: 29px;
    margin-right: 6px;
  }

  &__circle {
    width: 60px;
    height: 60px;
    margin-bottom: 4px;
    border-radius: 50%;
    background: #f5f5f5;
    border: 1px solid rgba(215, 215, 215, 0.4);
  }

  &__circle-small {
    width: 36px;
    height: 36px;
    margin-right: 4px;
    border-radius: 50%;
    border: 1px solid rgba(215, 215, 215, 0.4);
  }
}

.content-label {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
    text-align: center;
    color: var(---sui_color_gray_dark2, #666);
    word-break: break-all;
  }
  &.content-label__small span {
    text-align: left;
  }

  &__active {
    font-weight: 600;
    color: var(---sui_color_gray_dark1, #000);
    span {
      font-weight: 500;
      color: var(---sui_color_gray_dark1, #000);
    }
  }

  &__rect {
    width: 52px;
    height: 24px;
    font-size: 11px;
    line-height: 13px;
  }
  &__rect-small {
    max-width: 60px;
    line-height: normal;
  }

  &__circle {
    width: 64px;
    height: 24px;
    font-size: 11px;
    line-height: 13px;
  }

  &__circle-small {
    max-width: 60px;
    line-height: normal;
  }
}

.picnav-item{
  &__icon-active {
    display: inline-flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
  }

  &__icon-ar {
    transform: rotate(-270deg);
  }
}
</style>
