<template>
  <div class="slide-content">
    <div 
      ref="slideInner" 
      class="slide-content-inner" 
      :style="innerStyle"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SSlideContent',
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    reverse: Boolean,
  },

  data() {
    return {
      moveX: 0,
    }
  },

  computed: {
    slotsLen() {
      return this.$slots.default?.()?.length || 0
    },
    innerWidth() {
      return this.$refs.slideInner?.offsetWidth || 0
    },
    singleWidth() {
      return this.innerWidth / this.slotsLen
    },
    innerStyle() {
      return {
        transform: `translateX(${this.moveX}px)`,
      }
    },
  },

  watch: {
    modelValue(i) {
      if (!this.reverse) {
        this.moveX = this.singleWidth * i * -1
      } else {
        this.moveX = this.singleWidth * i
      }
    },
  },
})
</script>

<style lang="less">
.slide-content {
  display: flex;
  overflow: hidden;
  .slide-content-inner {
    display: flex;
    // transition: transform 0.3s;
  }
}
</style>
