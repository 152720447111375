<template>
  <div class="slide-pagination">
    <div
      v-for="(_, index) in list"
      :key="index"
      class="slide-pagination-item"
      :class="{ 'pagination-active': index === activeIndex }"
    ></div>
  </div>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SlidePagination',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
})
</script>

<style lang="less" scoped>
.slide-pagination {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;

  &-item {
    background-color: #D9D9D9;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &-item + &-item {
    margin-left: 6px;
  }

  .pagination-active {
    background-color: #000;
  }
}
</style>
