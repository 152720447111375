<template>
  <div 
    class="picTopNav-tab" 
    :style="tabStyle"
  >
    <Slidetab 
      v-model="currentModelValue" 
      :reverse="reverse" 
      :space-between="24"
    >
      <SlidetabItem
        v-for="(item, index) in list"
        :key="item.tabId"
        :slide-id="index"
        class="picTopNav-tab__item"
      >
        <span
          v-if="item.tabName"
          v-expose="getAnalysisData('2-17-21', item, index)"
          :class="[
            {
              active: modelValue === index,
            },
            'picTopNav-tab__inner',
          ]"
          :data-tabname="item.tabName"
        >
          {{ item.tabName }}
        </span>
      </SlidetabItem>
    </Slidetab>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { expose } from 'public/src/pages/common/analysis/directive'
import Slidetab from 'public/src/pages/common/slidetab/SlideTab.vue'
import SlidetabItem from 'public/src/pages/common/slidetab/SlidetabItem.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default defineComponent({
  components: {
    Slidetab,
    SlidetabItem,
  },
  directives: {
    expose,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
    reverse: Boolean,
    tabStyle: {
      type: Object, 
      default: () => ({})
    }
  },

  computed: {
    currentModelValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:model-value', v)
      },
    },
  },

  watch: {
    currentModelValue(index) {
      daEventCenter.triggerNotice({
        daId: '2-17-22',
        extraData: {
          ...this.list[index],
          index
        }
      })
    }
  },

  methods: {
    getAnalysisData(id, item, index) {
      return {
        id,
        code: 'picTopNavTab',
        delayReady: 1500,
        data: {
          ...item,
          index,
        },
      }
    },
  },
})
</script>

<style lang="less">
.picTopNav-tab {
  position: relative;
  display: flex;
  padding: 0 0.32rem;
  height: 38 / 37.5rem;

  &__item {
    display: flex;
    align-items: center;
  }

  &__inner {
    // line-height: 1.173333rem;
    line-height: 38 / 37.5rem;
    font-size: 12px;
    color: #767676;
    // tip: 解决加粗字体后抖动
    &::before {
      display: block;
      content: attr(data-tabname);
      font-weight: bold;
      visibility: hidden;
      height: 1px;
      color: transparent;
      margin-bottom: -1px;
      overflow: hidden;
    }

    &.active {
      color: #222;
      font-weight: 600;
    }
  }
}
</style>
