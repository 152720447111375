<template>
  <ScrollSticky
    v-if="list.length"
    id="scroll-top-pic-top-nav"
    ref="stickyComp"
    v-model:is-sticky-status="isStickyStatus"
    v-model:is-small-status="isSmallStatus"
    v-model:sticky-position="stickyPosition"
    :top="stickyConfig.top"
    :z-index="stickyConfig.zIndex"
    :translage-y="stickyConfig.translageY"
    :sticky-height="stickyConfig.stickyHeight"
    :root-margin-top="stickyConfig.rootMarginTop"
    :sticky-diff="stickyConfig.stickyDiff"
    :is-config-sticky="isConfigSticky"
    :is-config-small="isConfigSmall"
    :small-height-diff="stickyConfig.smallHeightDiff"
    :loading="loading"
    :opacity-transition="stickyConfig.opacityTransition"
    data-filter-bar-type="picTopNav"
  >
    <component
      :is="curComponent"
      @change="onChange"
    />
  </ScrollSticky>
</template>

<script setup>
import { ref, watch, toRefs, computed, provide } from 'vue'

import { usePicTopNavLayout } from './hooks/index.js'
import { useStickyCommonConfig } from 'public/src/pages/components/FilterBar/hooks/stickyConfig/index.js'
import { useIsConfigSmall, useIsConfigSticky, useStickyConfig } from 'public/src/pages/components/FilterBar/hooks/stickyConfig/picTopNav.js'
// uilst
import { PIC_TOP_NAV_LAYOUT } from 'public/src/pages/components/FilterBar/utils/const.js'
import useFirstIn from 'public/src/pages/components/FilterBar/hooks/useFirstIn.js'

// components
import ScrollSticky from 'public/src/pages/components/FilterBar/components/ScrollSticky.vue'
import LayoutPicks from './components/Layout/LayoutPicks.vue'
import LayoutBlank from './components/Layout/LayoutBlank.vue'
import LayoutList from './components/Layout/LayoutList.vue'
import LayoutTabs from './components/Layout/LayoutTabs.vue'
import LayoutCard from './components/Layout/LayoutCard.vue'

// defineAsyncComponent
// const LayoutPicks = defineAsyncComponent(() => import('./components/Layout/LayoutPicks.vue'))
// const LayoutBlank = defineAsyncComponent(() => import('./components/Layout/LayoutBlank.vue'))
// const LayoutList = defineAsyncComponent(() => import('./components/Layout/LayoutList.vue'))
// const LayoutTabs = defineAsyncComponent(() => import('./components/Layout/LayoutTabs.vue'))

const props = defineProps({
  list: { type: Array, default: () => [] },
  locals: { type: Object, default: () => ({}) },
  filterBar: { type: Object, default: () => ({}) },
  listAbtResult: { type: Object, default: () => ({}) },
  catInfo: { type: Object, default: () => ({}) },
  language: { type: Object, default: () => ({}) },
  queryParams: { type: Object, default: () => ({}) },
  constantData: { type: Object, default: () => ({}) },
  loading: { type: Boolean, default: false },
  picTopNavInfo: { type: Object, default: () => ({}) },
  dynamicStickyConfig: { type: Object, default: () => ({}) },
  fixedSmallStatus: { type: String, default: '' },
  hasSearchBannerCard: { type: Boolean, default: false },
  hasBgBanner: { type: Boolean, default: false },
  tabBgColor: { type: String, default: 'transparent' },
  hasCorrectionWord: { type: Boolean, default: false },
  isUseSearch: { type: Boolean, default: false },
  isVisible: { type: Boolean, default: false }
})

const { list, locals, catInfo, filterBar, listAbtResult, language, queryParams, constantData, loading, fixedSmallStatus, dynamicStickyConfig, tabBgColor, isUseSearch, hasSearchBannerCard, hasCorrectionWord, hasBgBanner, isVisible } = toRefs(props)

const picTopNavLayout = usePicTopNavLayout({
  list,
  catInfo,
  hasSearchBannerCard: hasSearchBannerCard,
  hasBgBanner: hasBgBanner,
  hasCorrectionWord: hasCorrectionWord
})
const curComponent = computed(() => {
  switch (picTopNavLayout.value) {
    case PIC_TOP_NAV_LAYOUT.PICKS:
      return LayoutPicks
    case PIC_TOP_NAV_LAYOUT.BLANK:
      return LayoutBlank
    case PIC_TOP_NAV_LAYOUT.TABS:
      return LayoutTabs
    case PIC_TOP_NAV_LAYOUT.CARD:
      return LayoutCard
    default:
      return LayoutList
  }
})
const stickyComp = ref()
const { isStickyStatus, isSmallStatus, stickyPosition } = useStickyCommonConfig(fixedSmallStatus)
const isConfigSmall = useIsConfigSmall({ catInfo, filterBar, list, fixedSmallStatus })
const isConfigSticky = useIsConfigSticky({ catInfo, filterBar, list, dynamicStickyConfig })
const stickyConfig = useStickyConfig({ catInfo, dynamicStickyConfig, filterBar, isConfigSticky, isStickyStatus, list, picTopNavLayout, listAbtResult })
const firstIn = useFirstIn(catInfo, filterBar)

watch(firstIn, (flag) => {
  flag && stickyComp.value?.resetSticky()
})
watch(isSmallStatus, (flag) => {
  appEventCenter.emit('picTopNavStatusChange', flag)
})

watch(fixedSmallStatus, (flag) => {
  isSmallStatus.value = flag === 'small'
})
// 标签点击后吸顶
const toStickyByClick = () => {
  isSmallStatus.value = true
  if (isConfigSticky.value) {
    stickyComp.value?.toStickyByClick()
  }
}
// 筛选后吸顶
const toStickyAfterFilter = () => {
  if (!isConfigSticky.value || (isConfigSticky.value && isStickyStatus.value)) {
    stickyComp.value?.toStickyAfterFilter()
  }
}

// 重置滚动
const resetSticky = () => {
  stickyComp.value?.resetSticky()
}

const resetScrollExpand = () => stickyComp.value?.resetScrollExpand()
const updateVDom = () => stickyComp.value?.updateVDom()
const updatePlaceholder = () => stickyComp.value?.updatePlaceholder()

defineExpose({ list, isStickyStatus, resetSticky, toStickyByClick, resetScrollExpand, toStickyAfterFilter, updateVDom, updatePlaceholder })


provide('list', list)
provide('locals', locals)
provide('isSmallStatus', isSmallStatus)
provide('catInfo', catInfo)
provide('language', language)
provide('filterBar', filterBar)
provide('isStickyStatus', isStickyStatus)
provide('queryParams', queryParams)
provide('listAbtResult', listAbtResult)
provide('constantData', constantData)
provide('tabBgColor', tabBgColor)
provide('isUseSearch', isUseSearch)
provide('hasBgBanner', hasBgBanner)
provide('isVisible', isVisible)

const emits = defineEmits(['change'])
const onChange = params => emits('change', params)
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PicTopNav',
})
</script>

<style lang="less" scoped>
.ptn-container {
  background-color: #fff;
}
</style>
