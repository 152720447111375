<!-- 适配picks页，纯文本导航，使用基础tab组件 -->
<template>
  <STab 
    v-model="activeIndex"
    type="auto"
    :align="align"
    @change="onChange"
  >
    <STabItem 
      v-for="(item, index) in list"
      :id="index"
      :key="item.value"
    >
      {{ item.label }}
    </STabItem>
  </STab>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TextNavList',
})
</script>

<script setup>
import { ref, inject, computed } from 'vue'
import { STab } from '@shein-aidc/sui-tab/mobile'
import { STabItem } from '@shein-aidc/sui-tab-item/mobile'

const props = defineProps({
  list: { type: Array, default: () => [] },
  activeValue: { type: [String, Number], default: '' } // 当前选中的值
})

const emits = defineEmits(['change'])
const activeIndex = ref(
  props.list.findIndex(item => item.value === props.activeValue)
)
const constantData = inject('constantData', {})
const align = computed(() => constantData?.GB_cssRight ? 'right' : 'left')

const onChange = () => {
  emits('change', activeIndex.value)
}
</script>
