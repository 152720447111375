/**
 * 在服务端/客户端渲染时，头部高度不一样导致Vue warn
 */

import { ref, onMounted } from 'vue'

export default function useHeaderHeight () {
  const heightHeight = ref(44)

  onMounted(() => {
    heightHeight.value = Math.floor(1.17 * 37.5 / 375 * document.body.clientWidth)
  })

  return heightHeight
}
