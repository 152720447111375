<template>
  <ScrollSticky
    id="scroll-top-nav-bar"
    ref="stickyComp"
    :top="stickyConfig.top"
    :z-index="stickyConfig.zIndex"
    :translage-y="stickyConfig.translageY"
    :sticky-height="stickyConfig.stickyHeight"
    :root-margin-top="stickyConfig.rootMarginTop"
    :is-config-sticky="isConfigSticky"
    v-model:is-sticky-status="isStickyStatus"
    :loading="loading"
  >
    <s-network-error-alert
      v-if="enableNetworkError"
      :description="language.SHEIN_KEY_PWA_31743"
    />
    <component 
      :is="currentComponent"
      ref="instanceComponent"
      @change="onChange"
      @saveCurrAttr="onSaveCurrAttr"
      @visibleChange="onVisibleChange"
      @toStickyByClick="onToStickyByClick"
    />
  </ScrollSticky>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NavBar',
})
</script>

<script setup>
import { SNetworkErrorAlert } from '@shein-aidc/sui-network-error-alert/mobile'
import { ref, provide, toRefs, computed, defineExpose } from 'vue'

import ScrollSticky from 'public/src/pages/components/FilterBar/components/ScrollSticky.vue'
import SortExport from'./components/Layout/SortExport.vue'
import SortQuick from'./components/Layout/SortQuick.vue'
import AttrExport from'./components/Layout/AttrExport.vue'

// hooks
import { useStickyCommonConfig } from 'public/src/pages/components/FilterBar/hooks/stickyConfig/index.js'
import { useNavBarType } from './hooks/index'
import { useIsConfigSticky, useStickyConfig } from 'public/src/pages/components/FilterBar/hooks/stickyConfig/navBar.js'
// utils
import { NAV_BAR_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

const props = defineProps({
  // scrollAction: { type: Function, default: function () {} },
  enableNetworkError: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  listAbtResult: { type: Object, default: () => ({}) },
  locals: { type: Object, default: () => ({}) },
  filterBar: { type: Object, default: () => ({}) },
  queryParams: { type: Object, default: () => ({}) },
  language: { type: Object, default: () => ({}) },
  picTopNav: { type: Array, default: () => ([]) },
  catInfo: { type: Object, default: () => ({}) },
  sum: { type: [String, Number], default: '' },
  closeBottomLeft: { type: Boolean, default: false },
  sortMap: { type: Array, default: () => [] },
  sortMap2: { type: Array, default: () => [] },
  sortAllList: { type: Array, default: () => [] },
  fhContext: { type: Object, default: () => ({}) },
  googleContext: { type: Object, default: () => ({}) },
  constantData: { type: Object, default: () => ({}) },
  dynamicStickyConfig: { type: Object, default: () => ({}) },
  isConfigAutoSticky: { type: Boolean, default: false }, // 是否自动吸顶【默认false，trend页面使用】
  autoStickyTime: { type: Number, default: 0 }, // 自动吸顶时间【默认0，如需考虑图文小图切换可以设置300】
})

const { 
  locals,
  filterBar,
  listAbtResult,
  queryParams,
  language,
  picTopNav,
  catInfo,
  sum,
  sortAllList,
  loading,
  sortMap,
  sortMap2,
  fhContext,
  googleContext,
  closeBottomLeft,
  constantData,
  dynamicStickyConfig,
  isConfigAutoSticky,
  autoStickyTime,
} = toRefs(props)


// const SortExport = defineAsyncComponent({ loader: defineAsyncComponent(() => import('./components/Layout/SortExport.vue'), delay: 0 }))
// const SortQuick = defineAsyncComponent({ loader: defineAsyncComponent(() => import('./components/Layout/SortQuick.vue'), delay: 0 }))
// const AttrExport = defineAsyncComponent({ loader: defineAsyncComponent(() => import('./components/Layout/AttrExport.vue'), delay: 0 }))

const navBarType = useNavBarType({ filterBar, picTopNav, listAbtResult })
const currentComponent = computed(() => {
  if (navBarType.value === NAV_BAR_TYPE.SORT_EXPORT) return SortExport
  if (navBarType.value === NAV_BAR_TYPE.ATTR_EXPORT) return AttrExport

  return SortQuick
})

// sticky & scroll
const stickyComp = ref()
const { isStickyStatus } = useStickyCommonConfig()
const isConfigSticky = useIsConfigSticky({ filterBar, picTopNav, dynamicStickyConfig })
const stickyConfig = useStickyConfig({ catInfo, filterBar, isConfigSticky, isStickyStatus, dynamicStickyConfig, listAbtResult })


const instanceComponent = ref()
const openSideFilter = () => instanceComponent.value?.openSideFilter()
const updateCateData = (data) => instanceComponent.value?.updateCateData?.(data)

const isPicks = computed(() => catInfo.value?.type === 'picks')
// 标签点击后吸顶
const toStickyByClick = () => {
  if (isConfigSticky.value && !isPicks.value) {
    stickyComp.value?.toStickyByClick()
  }
}
// 筛选后吸顶
const toStickyAfterFilter = () => {
  if (!isConfigSticky.value || (isConfigSticky.value && isStickyStatus.value) && !isPicks.value) {
    stickyComp.value?.toStickyAfterFilter()
  }
}
// 重置滚动
const resetSticky = () => {
  stickyComp.value?.resetSticky()
}

const updateVDom = () => stickyComp.value?.updateVDom()

const resetScrollExpand = () => stickyComp.value?.resetScrollExpand()

defineExpose({ isStickyStatus, resetSticky, openSideFilter, toStickyByClick, resetScrollExpand, toStickyAfterFilter, updateCateData, updateVDom })

provide('sum', sum)
provide('locals', locals)
provide('catInfo', catInfo)
provide('sortMap', sortMap)
provide('sortMap2', sortMap2)
provide('filterBar', filterBar)
provide('sortAllList', sortAllList)
provide('loading', loading)
provide('language', language)
provide('queryParams', queryParams)
provide('listAbtResult', listAbtResult)
provide('fhContext', fhContext)
provide('googleContext', googleContext)
provide('closeBottomLeft', closeBottomLeft)
provide('constantData', constantData)
provide('isConfigAutoSticky', isConfigAutoSticky)
provide('autoStickyTime', autoStickyTime)

const emits = defineEmits([
  'change',
  'toStickyByClick',
  'saveCurrAttr',
  'visibleChange',
])
const onChange = params => emits('change', params)
const onToStickyByClick = () => emits('toStickyByClick')
const onSaveCurrAttr = (id, type) => emits('saveCurrAttr', id, type)
const onVisibleChange = flag => emits('visibleChange', flag)
</script>

<style lang="less">
.nav-bar .dropdown-label__container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
  font-size: 12px;
  white-space: nowrap;
  height: 36px;
  color: rgba(0,0,0,0.6);
}

.nav-bar .dropdown-label__content{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  white-space: nowrap;
  height: 100%;
}

.nav-bar .dropdown-label__content-selected .dropdown-label__suffix {
  color: #000;
}
</style>

