<template>
  <ScrollSticky 
    v-if="tagAllList.length"
    id="scroll-top-cloud-tags"
    ref="stickyComp"
    :top="stickyConfig.top"
    :z-index="stickyConfig.zIndex"
    :translage-y="stickyConfig.translageY"
    :sticky-height="stickyConfig.stickyHeight"
    :root-margin-top="stickyConfig.rootMarginTop"
    :is-config-sticky="isConfigSticky"
    v-model:is-sticky-status="isStickyStatus"
    :loading="loading"
  >
    <component 
      :is="tagComponent"
      ref="refTagComponent"
      :tag-all-list="tagAllList"
      :cloud-tags="cloudTags"
      :price-tags="priceTags"
      :cate-tags="cateTags"
      :tsp-tags="tspTags"
      :kid-tags="kidTags"
      :attr-tags="attrTags"
      :brand-tags="brandTags"
      :custom-tags="customTags"
      :is-spa-page="isSpaPage"
      :is-second-load="isSecondLoad"
      :is-default-kid-toast="isDefaultKidToast"
      :is-show-kid-popover="isShowKidPopover"
      :is-show-age-limit-dialog="isShowAgeLimitDialog"
      @onSwiperInit="onSwiperInit"
      @onSwiperSlideEnd="onSwiperSlideEnd"
      @saveCurrAttr="saveCurrAttr"
      @toStickyByClick="onToStickyByClick"
      @change="onChange"
      @visibleChange="onVisibleChange"
    />
  </ScrollSticky>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CloudTags',
})
</script>

<script setup>
import { ref, computed, watch, toRefs, provide, onMounted, onDeactivated } from 'vue'

import ScrollSticky from 'public/src/pages/components/FilterBar/components/ScrollSticky.vue'

import { TAG_LAYOUT, FILTER_BAR_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'

// hooks
import { useStickyCommonConfig } from 'public/src/pages/components/FilterBar/hooks/stickyConfig/index.js'
import { useKidTags } from 'public/src/pages/components/FilterBar/hooks/useKids.js'
import { useCloudTags } from 'public/src/pages/components/FilterBar/hooks/useCloudTag.js'
import { usePriceTags } from 'public/src/pages/components/FilterBar/hooks/usePrices.js'
import { useCateTags } from 'public/src/pages/components/FilterBar/hooks/useCategory.js'
import { useTspTags } from 'public/src/pages/components/FilterBar/hooks/useTsp'
import { useCustomTags } from 'public/src/pages/components/FilterBar/hooks/useCustom'
import { useBrandTags } from 'public/src/pages/components/FilterBar/hooks/useBrand.js'
import { useDailyTags } from 'public/src/pages/components/FilterBar/hooks/useDaily.js'
import { useAttrTags } from 'public/src/pages/components/FilterBar/hooks/useAttr.js'
import { useIsConfigSticky, useStickyConfig } from 'public/src/pages/components/FilterBar/hooks/stickyConfig/cloudTags.js'


// utils
import { tagListSortByTagCloudSlot, tagSortByCccxFilter } from './utils/index.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

import TagSwiper from './components/Layout/TagSwiper.vue'
import TagTiled from './components/Layout/TagTiled.vue'

// const TagSwiper = defineAsyncComponent({ loader: defineAsyncComponent(() => import('./components/Layout/TagSwiper.vue'), delay: 0 }))
// const TagTiled = defineAsyncComponent({ loader: defineAsyncComponent(() => import('./components/Layout/TagTiled.vue'), delay: 0 }))

const props = defineProps({
  loading: { type: Boolean, default: false },
  layout: { type: String, default: TAG_LAYOUT.SWIPER },  // 布局,默认swiper滑动
  listAbtResult: { type: Object, default: () => ({}) },
  locals: { type: Object, default: () => ({}) },
  filterBar: { type: Object, default: () => ({}) },
  queryParams: { type: Object, default: () => ({}) },
  language: { type: Object, default: () => ({}) },
  picTopNav: { type: Array, default: () => ([]) },
  catInfo: { type: Object, default: () => ({}) },
  sum: { type: [String, Number], default: '' },
  closeBottomLeft: { type: Boolean, default: false },
  picksMenuList: { type: Array, default: () => [] },
  fhContext: { type: Object, default: () => ({}) },
  googleContext: { type: Object, default: () => ({}) },
  constantData: { type: Object, default: () => ({}) },
  bannerTag: { type: Object, default: () => ({}) },
  isSpaPage: { type: Boolean, default: false },
  isSecondLoad: { type: Boolean, default: false },
  isDefaultKidToast: { type: Boolean, default: false },
  isShowAgeLimitDialog: { type: Boolean, default: false },
  isShowKidPopover: { type: Boolean, default: true },
  dynamicStickyConfig: { type: Object, default: () => ({}) },
})

const { 
  locals,
  filterBar,
  listAbtResult,
  queryParams,
  language,
  picTopNav,
  catInfo,
  sum,
  loading,
  closeBottomLeft,
  picksMenuList,
  fhContext,
  googleContext,
  constantData,
  dynamicStickyConfig,
} = toRefs(props)

// 标签组件
const tagComponent = computed(() => {
  return props.layout === TAG_LAYOUT.SWIPER ? TagSwiper : TagTiled
})

// 所有类型标签
const cloudTags = useCloudTags({ filterBar, listAbtResult, locals })
const priceTags = usePriceTags({ filterBar, listAbtResult, queryParams, language, catInfo })
const [cateTags, updateCateTags] = useCateTags({ catInfo, filterBar, language, listAbtResult, queryParams })
const tspTags = useTspTags({ filterBar, queryParams })
const dailyTags = useDailyTags({ filterBar, language })
const attrTags = useAttrTags({ filterBar, picTopNav, queryParams })
const kidTags = useKidTags({ filterBar, language, queryParams })
const customTags = useCustomTags({ filterBar, queryParams })
const brandTags = useBrandTags({ filterBar, queryParams })
const tagAllList = ref([])

// sticky & scroll
const stickyComp = ref()
const { isStickyStatus } = useStickyCommonConfig()
const isConfigSticky = useIsConfigSticky({ filterBar, dynamicStickyConfig })
const stickyConfig = useStickyConfig({ catInfo, filterBar, isConfigSticky, isStickyStatus, dynamicStickyConfig, listAbtResult })


const isPicks = computed(() => catInfo.value?.type === 'picks')
// 标签点击后吸顶
const toStickyByClick = () => {
  if (isConfigSticky.value && !isPicks.value) {
    stickyComp.value?.toStickyByClick()
  }
}
// 筛选后吸顶
const toStickyAfterFilter = () => {
  if (!isConfigSticky.value || (isConfigSticky.value && isStickyStatus.value) && !isPicks.value) {
    stickyComp.value?.toStickyAfterFilter()
  }
}
// 重置滚动
const resetSticky = () => {
  stickyComp.value?.resetSticky()
}

const updateVDom = () => stickyComp.value?.updateVDom()

const resetScrollExpand = () => stickyComp.value?.resetScrollExpand()
// 更新分类数据
const updateCloudTags = (params) => {
  updateCateTags(params)
}

const refTagComponent = ref()
const clearActiveTagId = () => refTagComponent.value?.clearActiveTagId()

defineExpose({ isStickyStatus, resetSticky, toStickyByClick, resetScrollExpand, toStickyAfterFilter, updateCloudTags, updateVDom, clearActiveTagId })


const timer = ref(0)
const isMounted = ref(false)
// 标签曝光
const analysisExposeTags = () => {
  if (!isMounted.value) return
  clearTimeout(timer.value)

  const callback = () => {
    const cloudTags = []
    const { showTag, tag_id, type } = props.bannerTag
    showTag && type === 'oneClickPay' && cloudTags.push({
      value: tag_id,
      index: 0,
      isPicNavCate: false,
      tagCate: 'label'
    })

    tagAllList.value.forEach((tag, index) => {
      if(tag.tagItemType === TAG_ITEM_TYPE.KIDS ) return
      
      const obj = {
        value: tag.value,
        index: tag.tagIndex + 1,
        isPicNavCate: false,
        tagCate: 'label'
      }
      switch (tag.tagItemType) {
        case TAG_ITEM_TYPE.CATEGORY:
          obj.value = 'category'
          obj.tagCate = 'category'
          break
        case TAG_ITEM_TYPE.ATTR:
          obj.tagCate = 'attribute'
          break
        case TAG_ITEM_TYPE.DAILY:
          obj.value = 'date'
          break
        case TAG_ITEM_TYPE.PIC_NAV_CATE:
          obj.isPicNavCate = true
          break
        case TAG_ITEM_TYPE.PRICES:
          obj.value = 'price'
          obj.tagCate = 'price'
          break
        case TAG_ITEM_TYPE.TSP:
          obj.tagCate = 'tsp'
          break
        case TAG_ITEM_TYPE.BRAND:
          obj.tagCate = 'brand'
          break
        default:
          break
      }
      
      // 自定义筛选，不管tsp/属性均上报attribute
      tag.nodeType == 4 && (obj.tagCate = 'attribute') 

      cloudTags.push(obj)
    })
      
    analysis.exposeCloudTag({
      cloudTags
    })
  }
  timer.value = setTimeout(() => {
    clearTimeout(timer.value)
    callback()
  }, 1500)
}

const updateTagAllList = () => {
  // product Type 排序
  let list = tagSortByCccxFilter([
    ...cloudTags.value,
    ...dailyTags.value,
    ...priceTags.value,
    ...cateTags.value,
    ...tspTags.value,
    ...attrTags.value,
    ...brandTags.value,
    ...customTags.value
  ], filterBar.value)
    
  // 标签插坑
  list = tagListSortByTagCloudSlot(list, filterBar.value)
    
  if (kidTags.value.length) {
    list.unshift(kidTags.value[0])  // 默认第一位 
  }
  
  let tagIndex = 0
  list.forEach((item) => {
    if (item.tagItemType === TAG_ITEM_TYPE.KIDS) return
    item.tagIndex = tagIndex
    tagIndex++
  })
    
  tagAllList.value = list
}

// 监听依赖项的变化
watch(
  [cloudTags, priceTags, cateTags, tspTags, dailyTags, attrTags, kidTags, brandTags, customTags],
  async () => updateTagAllList(),
  { immediate: true } 
)

watch(tagAllList, () => {
  analysisExposeTags()
}, { immediate: true })

onMounted(() => {
  isMounted.value = true
  updateTagAllList()
  analysisExposeTags()
})

onDeactivated(() => {
  // 埋点延迟1.5s上报，可能存在快速返回后埋点依旧上报【如列表进店铺快速返回列表】
  clearTimeout(timer.value)
}) 

provide('sum', sum)
provide('catInfo', catInfo)
provide('loading', loading)
provide('language', language)
provide('filterBar', filterBar)
provide('queryParams', queryParams)
provide('listAbtResult', listAbtResult)
provide('closeBottomLeft', closeBottomLeft)
provide('picksMenuList', picksMenuList)
provide('fhContext', fhContext)
provide('googleContext', googleContext)
provide('constantData', constantData)
provide('filterBarType', FILTER_BAR_MAP.CLOUD_TAGS)

const emits = defineEmits([
  'onSwiperInit',
  'onSwiperSlideEnd',
  'autoSticky',
  'saveCurrAttr',
  'toStickyByClick',
  'change',
  'visibleChange',
])
const onSwiperInit = (beginIndex, endIndex) => emits('onSwiperInit', beginIndex, endIndex)
const onSwiperSlideEnd = (beginIndex, endIndex) => emits('onSwiperSlideEnd', beginIndex, endIndex)
const saveCurrAttr = (id, type) => emits('saveCurrAttr', id, type)
const onToStickyByClick = () => emits('toStickyByClick')
const onChange = params => emits('change', params)
const onVisibleChange = visible => emits('visibleChange', visible)
</script>
