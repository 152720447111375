<template>
  <div class="videotex-nav">
    <Slidetab
      ref="slideTabEl"
      v-model="currentModelValue"
      :reverse="reverse"
      :invert-select="true"
      :space-between="spaceBetween"
      dynamic-slide
      :slide-ready="secondScreenItemsVisible || !firstRendering"
      @done="$emit('done', $event)"
    >
      <SlidetabItem
        v-for="(item, index) in firstScreenItems"
        :key="item.nav_id"
        v-expose="getAnalysisData('2-17-17', item, index)"
        :slide-id="index"
        @change="handleSelect"
      >
        <ImgNavItem
          ref="imgNavItems"
          :is-jump="isJump"
          :to-query="toQuery"
          :da-rid="item.nav_id"
          :lazy-img="lazyImg"
          :active="currentModelValue === index"
          :item="item"
          :small-tag="isSmall"
          :close-lazy="tabIndex <= 0 && index < 5"
        />
      </SlidetabItem>

      <template v-if="secondScreenItemsVisible || !firstRendering">
        <SlidetabItem
          v-for="(item, index) in secondScreenItems"
          :key="item.nav_id"
          v-expose="getAnalysisData('2-17-17', item, index + 5)"
          :slide-id="index + 5"
          @change="handleSelect"
        >
          <ImgNavItem
            ref="imgNavItems"
            :is-jump="isJump"
            :to-query="toQuery"
            :da-rid="item.nav_id"
            :lazy-img="lazyImg"
            :active="currentModelValue === index + 5"
            :item="item"
            :small-tag="isSmall"
            :close-lazy="false"
          />
        </SlidetabItem>
      </template>

      <!-- next 有tab时展示, 并且不是tab最后一个 -->
      <template
        v-if="nextText" 
        #right
      >
        <div 
          class="videotex-nav__item-next"
        >
          <span>{{ nextText }}</span>
        </div>
      </template>
    </Slidetab>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { expose } from 'public/src/pages/common/analysis/directive'
import ImgNavItem from './ImgNavItem.vue'
import Slidetab from 'public/src/pages/common/slidetab/SlideTab.vue'
import SlidetabItem from 'public/src/pages/common/slidetab/SlidetabItem.vue'
import { PIC_TOP_NAV_PREFIX } from 'public/src/pages/product_list_v2/analytics/index.js'

export default defineComponent({
  name: 'PicTopNavItem',
  directives: {
    expose,
  },
  inject: {
    isImgDataTypeDirectionColumnAbtOn: {
      default: () => () => false
    }
  },
  components: {
    ImgNavItem,
    Slidetab,
    SlidetabItem,
  },
  props: {
    modelValue: {
      type: [Number],
      default: () => -1
    },
    // 禁止选中
    disabled: {
      type: Boolean,
      default: false,
    },
    // 吸顶状态
    isSmall: {
      type: Boolean,
      default: () => false,
    },
    // 图文数据
    picTopNavList: {
      type: Array,
      default: () => [],
    },
    // 图文导航id
    picTopNavId: {
      type: [String, Number],
      default: () => 0,
    },
    reverse: Boolean,
    lazyImg: {
      type: String,
      default: () => ''
    },
    locals: {
      type: Object,
      default: () => ({}),
    },
    nextText: {
      type: String,
      default: ''
    },
    catInfo: {
      type: Object,
      default: () => ({})
    },
    tabItem: {
      type: Object,
      default: () => ({})
    },
    tabIndex: {
      type: Number,
      default: -1
    },
    firstRendering: {
      type: Boolean,
      default: true,
    },
    isJump: {
      type: Boolean,
      default: false
    },
    toQuery: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      secondScreenItemsVisible: false,
    }
  },

  computed: {
    currentModelValue: {
      get() {
        return this.disabled ? -1 : this.modelValue
      },
      set(v) {
        if (v === this.modelValue) return
        this.$emit('update:model-value', v)
      }
    },
    // 首屏的图文数据, 取前 5 个
    firstScreenItems() {
      return this.picTopNavList.slice(0, 5)
    },
    // 次屏的图文数据, 取 5 个以后的, 延迟渲染
    secondScreenItems() {
      return this.picTopNavList.slice(5)
    },
    spaceBetween() {
      if (!(this.isJump && this.toQuery)) return 10
      if (this.isSmall) return 12
      
      return 8
    }
  },

  mounted() {
    if (this.firstRendering) {
      setTimeout(() => {
        this.secondScreenItemsVisible = true
      }, 1500)
    }

    appEventCenter && appEventCenter.on('goodsLoadedOnPageList', this.resetMovex)
  },
  beforeUnmount () { 
    appEventCenter && appEventCenter.off('goodsLoadedOnPageList', this.resetMovex)
  },

  methods: {
    handleSelect (index, isSelect) {
      const picNavItem = this.picTopNavList[index]
      this.$emit('select', picNavItem, index, isSelect)
    },

    // 图文曝光
    getAnalysisData (id, item, index) {
      return {
        id,
        code: 'picTopNav',
        prefix: PIC_TOP_NAV_PREFIX,
        delayReady: 1500,
        data: {
          ...item,
          index,
          catInfo: this.catInfo,
          ...this.tabItem,
          tabIndex: this.tabIndex,
          isImgDataTypeDirectionColumnAbtOn: this.isImgDataTypeDirectionColumnAbtOn(),
        },
      }
    },

    // 外部切换页面调用， 当页面切换， 容器没有销毁时调用此方法
    reset() {
      this.$refs.slideTabEl?.updateReset()
      this.$refs.imgNavItems.forEach(vm => vm.reset())
    },

    resetMovex() {
      this.$refs.slideTabEl?.resetMoveX()
    }
  },
})
</script>

<style lang="less">
.videotex-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.32rem /* 12/37.5 */;
  width: 100vw;
  overflow: hidden;
  background: #fff;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__item-next {
    padding: 0 .213333rem /* 8/37.5 */;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;

    span {
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
