<template>
  <div
    class="videotex-item videotex-item-exist"
    :class="elClass"
    v-bind="adaOptions"
  >
    <!-- 图片 -->
    <img
      :class="[
        !smallTag ? 'videotex-image' : 'videotex-image-small',
        !closeLazy ? 'lazyload' : '',
      ]"
      v-bind="imgProps"
      :alt="item.nav_name"
    />

    <!-- 文字 -->
    <div :class="!smallTag ? 'videotex-content' : 'videotex-content-small'">
      <!-- 无吸顶样式 -->
      <div class="videotex-content__target">
        <span>{{ item.nav_name }}</span>
      </div>
    </div>

    <!-- 选中按钮 -->
    <Icon
      v-show="active && !isJump"
      class="not-fsp-element"
      :style="iconStyle"
      name="sui_icon_share_cancel_16px" 
      size="16px" 
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

export default defineComponent({
  name: 'ImgNavItem',
  components: {
    Icon,
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  props: {
    lazyImg: {
      type: String,
      default: () => '',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: () => false,
    },
    // 是否是小图文
    smallTag: {
      type: Boolean,
      default: () => false,
    },
    closeLazy: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => '',
    },
    isJump: {
      type: Boolean,
      default: false,
    },
    toQuery: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    elClass () {
      return {
        'videotex-item-exist__circle': this.isJump || this.toQuery,
        'videotex-item-exist__circle-small': (this.isJump || this.toQuery) && this.smallTag,
        'videotex-item-exist__active': !this.isJump && this.active, // 选中
        'videotex-item-exist__small': this.smallTag,
      }
    },
    iconStyle() {
      return {
        transform: this.GB_cssRight ? 'rotate(-90deg)' : 0,
      }
    },
    adaOptions() {
      let options = {}
      if (!this.smallTag) {
        // 大图时加上
        options = {
          tabindex: 0,
          'aria-label': this.item.nav_name,
        }
      }
      return options
    },
    imgProps() {
      if (!this.closeLazy) {
        return { src: this.lazyImg, 'data-src': this.item.nav_img, 'data-design-width': 0 }
      } else {
        return { src: this.getCutImg(this.item.nav_img, 0) }
      }
    },
  },

  methods: {
    transformImg,
    reset() {
      const imgEl = this.$el.querySelector('.lazyload')
      
      if (imgEl) {
        imgEl.setAttribute('src', this.lazyImg)
        imgEl.removeAttribute('loaded')
      }
    },
    getCutImg(imgUrl, imgDesignWidth) {
      if (!this?.cutImg) {
        return this.transformImg({ img: imgUrl })
      }
      return this.cutImg(imgUrl, imgDesignWidth, {})
    }
  },
})
</script>

<style lang="less">
.videotex-item {
  position: relative;
  background: #f5f5f5;
  color: #666;
  // transition: opacity 0.25s ease-in-out;
  .icons-share_cancel {
    position: absolute;
    top: -1px;
    right: -1px;
    line-height: 1;
    color: #222 /* rw: #ff696e */;
  }

  span {
    font-size: 12px;
    transform: scale(0.9);
  }
}

.videotex-item-exist {
  padding: 0.133333rem /* 5/37.5 */;
  // width: 1.866667rem /* 70/37.5 */;
  // height: 2.613333rem /* 98/37.5 */;
  width: 62 / 37.5rem;
  height: 78 / 37.5rem;
  border: 1px solid transparent; // 防止添加边框抖动
  &__small {
    display: flex;
    padding: 0.133333rem 0.213333rem;
    max-width: 4.08rem /* 153/37.5 */;
    height: 1.04rem /* 39/37.5 */;
    width: auto;
  }

  // 选中后的样式
  &__active {
    border: 1px #222222 solid;
  }

  // 图片
  .videotex-image {
    display: flex;
    flex-shrink: 0;
    margin-bottom: 0.13rem;
    width: 100%;
    // height: 1.6rem;
    height: 44 / 37.5rem; // 图文导航
    object-fit: cover;
  }
  .videotex-image-small {
    display: flex;
    flex-shrink: 0;
    margin-right: 0.213333rem /* 8/37.5 */;
    width: 0.773333rem /* 29/37.5 */;
    height: 0.773333rem /* 29/37.5 */;
    object-fit: cover;
  }

  // 文字
  .videotex-content {
    text-align: center;
    font-size: 11 / 37.5rem;
    &__target {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.64rem;
      line-height: 0.32rem;
    }

    span {
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  // 小标签状态有图状态
  .videotex-content-small {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 0.39rem;
    }
  }


  // 跳转新页面
  &__circle {
    padding: 0;
    background: none;
    height: 89 / 37.5rem;
    

    // 图片
    .videotex-image {
      display: flex;
      flex-shrink: 0;
      margin-bottom: 0.13rem;
      width: 60 / 37.5rem;
      height: 60 / 37.5rem; // 图文导航
      object-fit: cover;
      border-radius: 50%;
      background-color: #F5F5F5;
      border: 1px solid #E5E5E5;
    }
  }


  &__circle-small {
      max-width: 100 / 37.5rem;
      height: 36 / 37.5rem;
      justify-content: start;

      .videotex-image-small {
        width: 36 / 37.5rem;
        height: 36 / 37.5rem; // 图文导航
        border-radius: 50%;
        background-color: #F5F5F5;
        border: 1px solid #E5E5E5;
        margin-right: 4 / 37.5rem;
      }

      .videotex-content-small{
        max-width: 60 / 37.5rem;
        overflow: hidden;
        justify-content: start;
      }
    }
}
</style>
