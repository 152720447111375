<template>
  <div class="slide-content-item">
    <slot></slot>
  </div>
</template>

<style lang="less" scoped>
.slide-content-item {
  position: relative;
  padding: 0 12px; // @flag-pic-padding 改之前搜一下这个标记
  width: 100vw;
}
</style>
