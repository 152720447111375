import { computed } from 'vue'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index'
import { getStickyConfigZIndex } from './utils'
import useHeaderHeight from 'public/src/pages/components/FilterBar/hooks/useHeaderHeight.js'

// 是否配置图文吸顶
export function useIsConfigSticky ({ filterBar, dynamicStickyConfig }) {
  return computed(() => {
    return dynamicStickyConfig.value.hasOwnProperty('isConfigSticky')
      ? dynamicStickyConfig.value.isConfigSticky
      : filterBar.value?.filterConfig?.isStickyCloudTags
  })
}

function getStickyConfigByPicks ({ filterBar, headerHeight, isConfigSticky }) {
  const { isStickyPicksPic, isStickySearch } = filterBar?.filterConfig
  let top = isStickyPicksPic ? 51 : headerHeight
  const height = 42 // 标签云高度
  top = top + (isStickySearch ? headerHeight : 0)
  return {
    top,
    rootMarginTop: top + height,
    translageY: isStickySearch ? 0 : headerHeight, // 下滑偏移量
    zIndex: getStickyConfigZIndex(isConfigSticky, isStickySearch),
    stickyHeight: height
  }
}

function getStickyConfigTop ({ isConfigSticky, isStickySearch, headerHeight }) {
  if (!isConfigSticky) return -1000
  
  return isStickySearch ? headerHeight : 0
}

export function useStickyConfig ({ catInfo, filterBar, isConfigSticky, dynamicStickyConfig  }) {
  const headerHeight = useHeaderHeight()
  
  return computed(() => {
    // if (!isConfigSticky.value) return {} // 非标签吸顶
    if (catInfo.value?.type === 'picks') return getStickyConfigByPicks(unrefs({ filterBar, isConfigSticky, headerHeight })) // picks图文特殊处理
    const isStickySearch = filterBar.value?.filterConfig?.isStickySearch
    const height = catInfo.value?.pageName === 'page_store' ? 38 : 42 // 标签云高度
    const { top: dynamicTop = 0 } = dynamicStickyConfig.value || {}
    const top = dynamicTop || getStickyConfigTop(unrefs({ isConfigSticky, isStickySearch, headerHeight }))
    
    return {
      top: isConfigSticky.value ? top : -100,
      zIndex: getStickyConfigZIndex(isConfigSticky.value, isStickySearch),
      rootMarginTop: top + height,
      translageY: isStickySearch ? 0 : headerHeight.value, // 下滑偏移量
      stickyHeight: height
    }
  })
}
