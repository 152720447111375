import { getQueryString, isObject } from '@shein/common-function'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
import { SEARCH_TYPE_ENUM } from 'public/src/pages/common/search_words/searchConst'
import { getSearchDiff, getTopNavigation } from 'public/src/pages/product_app/store/modules/product_list_v2/utils'

const homeToEntityReqSearch = function(catInfo, listAbtResult) {
  const { type } = catInfo || {}
  if (type !== 'entity') return false
  const { ListServer } = listAbtResult
  const homeToRealPageAbtConfig = {
    requestSearch: false,
  }
  let ListServerTestVal = ListServer?.p?.ListServer
  if (ListServerTestVal === 'Search') {
    homeToRealPageAbtConfig.requestSearch = true
  }

  return homeToRealPageAbtConfig?.requestSearch
}

// 兼容无效的 URI 组件字符会抛出错误，如str含 %
function safeDecodeURIComponent(str) {
  try {
    return decodeURIComponent(str)
  } catch (err) {
    return str
  }
}
/**
 * 获取页面名词
 *
 * copy from public/src/pages/product_list_v2/components/ListTopInfo.vue getTitle()
 */
const getListName = ({
  catInfo,
  currentCat,
  language,
}) => {
  switch (catInfo.type) {
    case 'entity':
      return currentCat.cat_name
    case 'selection':
      return currentCat.select_name_multi
    case 'daily_new':
      return language.SHEIN_KEY_PWA_15727
    case 'selling':
      return currentCat.valName
    // case 'search':
    //   return
    default:
      return '-'
  }
}

export class SA {
  constructor({ IS_RW }) {
    this.IS_RW = IS_RW
  }

  updatePageParam(updateValue) {
    if (!isObject(updateValue)) return console.warn('updateValue is not Object')

    SaPageInfo.page_param = {
      ...SaPageInfo.page_param,
      ...updateValue
    }

    sa('set', 'setPageData', SaPageInfo)
  }

  resetPageParam({ catInfo, sum, searchKeywords, currentCat, fhContext, googleContext, goodsCrowId, listAbtResult, extraData, request_ext, hypernymRequestExt, searchTraceId, searchRequestStatus, searchRecRequestStatus, language, filterCates, isTopTrendResultPage }) {
    const entityReqSearch = homeToEntityReqSearch(catInfo, listAbtResult)
    const { type, pageListType, styleType } = catInfo
    switch (true) {
      case type == 'entity' && !entityReqSearch:
        SaPageInfo.page_id = 3
        SaPageInfo.page_name = 'page_real_class'
        SaPageInfo.page_param = {
          category_id: catInfo.entity_id,
          source_category_id: currentCat.parent_id,
          page_list_type: pageListType,
          styleType,
          entrancetype: getQueryString({ key: 'entranceType' }) || '-'
        }
        break
      case type == 'selection':
        SaPageInfo.page_id = 46
        SaPageInfo.page_name = 'page_select_class'
        SaPageInfo.page_param = {
          category_id: catInfo.select_id,
          page_list_type: pageListType,
          styleType,
          entrancetype: getQueryString({ key: 'entranceType' }) || '-',
          product_select_id: getQueryString({ key: 'product_select_id' }) || ''
        }
        break
      case type == 'daily_new':
        SaPageInfo.page_id = 12
        SaPageInfo.page_name = 'page_goods_group'
        SaPageInfo.page_param = {
          group_content: 'daily_new',
          date: catInfo.daily || 'daily_new',
          result_count: sum || 0,
          page_list_type: pageListType,
          styleType,
        }
        break
      case type == 'search' || entityReqSearch:
        {
          const { word_type: word_from = '', result_type, cellsearchhot, pagefrom, entrancetype, after_card_info, source_goods_id } = searchWordsGlobalAnalysis.get()

          const searchWordArr = catInfo?.searchWord ? [catInfo.searchWord] : [] // 主搜词
          const filterWordsArr = catInfo?.filterWords?.split(',') || [] // 筛选词
          // 用户点击品类词7 历史词5 结果页child_id改为记录为"0"
          const resetChildIdSearchType = result_type === SEARCH_TYPE_ENUM.SUGGESTION_SEARCH_CATEGORY || result_type === SEARCH_TYPE_ENUM.RECENT_SEARCH
          const childId = resetChildIdSearchType ? { child_id: '0' } : {}

          const query_infor = Object.assign({}, hypernymRequestExt || {}, request_ext || {})
          const queryInforString = Object.entries(query_infor)
            .map(([key, value]) => `${key}_${value ?? ''}`)
            .join('|')

          const topTrendData = isTopTrendResultPage ? { entry_from: getQueryString({ key: 'entry_from' }) || '' } : {}
          SaPageInfo.page_param = {
            word_from,
            pagefrom: pagefrom || catInfo?.ici?.replace('`_fb`', searchKeywords.is_suggest_res ? '`fb1`' : '`fb0`') || searchWordsGlobalAnalysis.getPageFrom(),
            result_type,
            search_content: searchWordArr.concat(filterWordsArr).join('>'),
            result_count: +searchKeywords.is_suggest_res === 1 ? 0 : sum || 0,
            rec_word: searchKeywords.suggest_words,
            rec_count: searchKeywords.suggest_total,
            crowd_id: this.IS_RW ? undefined : Object.keys(goodsCrowId)
              .map(key => `${key}_${goodsCrowId[key]}`)
              .join(),
            // 锦囊搜索；其中0代表命中实验下未使用、1代表使用；；空代表未命中实验
            cellsearchhot: cellsearchhot ? cellsearchhot : listAbtResult['PwaCellSearchHot']?.p?.PwaCellSearchHot?.includes('type=B') ? '0' : '',
            search_request_status: searchRequestStatus || '',
            search_rec_request_status: searchRecRequestStatus || '',
            query_infor: queryInforString,
            after_card_info: after_card_info || '-', // 点后推搜索词信息
            source_goods_id: source_goods_id || '-', // 点后推搜索词来源的坑位商品id
            ...childId,
            ...topTrendData, // v.趋势搜索结果页屏蔽该功能 新增该埋点
          }
          if (getSearchDiff(catInfo).isStoreSearch) {
            SaPageInfo.page_id = 2987
            SaPageInfo.page_name = 'page_store_search'
            SaPageInfo.page_param.src_module = 'store_search'
            SaPageInfo.page_param.store_code = catInfo.store_code
          } else if (getSearchDiff(catInfo).isBrandSearch) {
            SaPageInfo.page_id = 2987
            SaPageInfo.page_name = 'page_chanel_search'
            SaPageInfo.page_param.src_module = 'chanel_search'
            SaPageInfo.page_param.tsp_code = catInfo.intent_channel_id
          } else {
            SaPageInfo.page_id = 16
            SaPageInfo.page_name = 'page_search'
            SaPageInfo.page_param.trace_id = searchTraceId || '-'
            SaPageInfo.page_param.result_content = searchWordsGlobalAnalysis.get().result_content
            SaPageInfo.page_param.entrancetype = entrancetype || '-'
            if (entityReqSearch) {
              SaPageInfo.page_param.category_id = catInfo.entity_id,
              SaPageInfo.page_param.result_type = 17
            }
          }
        }
        break
      case type == 'selling':
        SaPageInfo.page_id = 21
        SaPageInfo.page_name = 'page_goods_group'
        SaPageInfo.page_param = {
          group_content: 'selling_point',
          result_count: sum || 0,
          page_list_type: pageListType,
          styleType
        }
        break
    }

    const fallPageType = ['entity', 'selection', 'daily_new', 'search', 'picks', 'selling']
    if (fallPageType.includes(catInfo.type)) {

      const searchPageParams = {}
      if (catInfo.type === 'search') {
        searchPageParams.from_list_cate_id = getQueryString({ key: 'from_list_cate_id' })
      }

      SaPageInfo.page_param = {
        src_identifier: catInfo.url_from ? `uf=${catInfo.url_from}` : safeDecodeURIComponent(catInfo.src_identifier || ''),
        src_module: catInfo.url_from ? 'ads' : catInfo.src_module || '',
        src_tab_page_id: catInfo.src_tab_page_id || '',
        ...searchPageParams,
        ...SaPageInfo.page_param,
      }
    }

    let rec_result_count = {}
    // if(!SaPageInfo.page_param?.rec_result_count) {
    if(extraData.recResultCount) {
      const recResultCount = typeof extraData.recResultCount === 'number' ? extraData.recResultCount : '-'
      rec_result_count = { rec_result_count: recResultCount }
    }
    const recommend_count = catInfo.search_type !== 'store' ? { 'recommend_count': typeof extraData.recommendListPageNum === 'number' ? extraData.recommendListPageNum : '-' } : null
    let attribute = []
    const { attr_ids, brand_ids } = catInfo
    attr_ids && (attribute = attribute.concat((attr_ids?.split('-') ?? [])))
    brand_ids && (attribute = attribute.concat((brand_ids?.split(',') ?? []).map(id => `brand_${id}`)))
    attribute = attribute.length ? attribute.join('-') : '0'

    SaPageInfo.page_param = {
      // page info
      category_id: '',
      category: filterCates?.length ? 1 : 0,
      source_category_id: '0',
      result_count: sum,
      list_name: getListName({
        catInfo,
        currentCat,
        language,
      }),

      // filt
      ...getTopNavigation(catInfo),
      top_navigation_source: catInfo.top_navigation_source,

      sort: catInfo.sort || '0',
      sort_type: extraData.sortType || 'sort',
      child_id: catInfo.child_cat_id || '0',
      attribute,
      tsps: catInfo.tsp_ids || '0',
      price_range: `${catInfo.min_price || '-'}\`${catInfo.max_price || '-'}`,
      price_input: extraData.priceInput || '',
      tag_id: catInfo.tag_ids && `${catInfo.tag_ids}\`0` || catInfo.pic_cate_id && `${catInfo.pic_cate_id}\`1` || 0,

      // global traking
      aod_id: catInfo.aod_id || catInfo.ici?.match(/aod=([^_&]+)/)?.[1] || '0',
      pagefrom: catInfo.ici || catInfo.productsource,
      user_path: catInfo.userpath || '-',

      // list traking
      is_from_list_feeds: catInfo.is_from_list_feeds ? '1' : '2',
      sourceId: fhContext.rid,
      attributionToken: googleContext.attributionToken,

      result_Ioc: catInfo.result_Ioc || '',

      // 上位词商品总数
      ...rec_result_count,
      // 推荐商品总数
      ...recommend_count,
      ...(extraData.Searchboxform ? { 'Searchboxform': extraData.Searchboxform } : null ),
      ...SaPageInfo.page_param,
    }

    SaPageInfo.start_time = Date.now()

    sa('set', 'setPageData', SaPageInfo)
  }
}
