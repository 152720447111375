<template>
  <div class="slide-content">
    <div
      ref="slideInner"
      class="slide-content-inner"
      :style="innerStyle"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted, nextTick, inject } from 'vue'

const props = defineProps({
  list: { type: Array, default: () => [] },
  modelValue: { type: Number, default: 0 },
  reverse: { type: Boolean, default: false },
  isSmallStatus: { type: Boolean, default: false },
  isFixedWidth: { type: Boolean, default: true } // 是否固定宽度不变
})

const moveX = ref(0)
const slideInner = ref()
const innerStyle = computed(() => ({ transform: `translateX(${moveX.value}px)` }))
const isContainerVisible = inject('isVisible')

const setMoveX = () => {
  const innerWidth = slideInner.value?.offsetWidth || 0
  const singleWidth = innerWidth / props.list.length
  const newMoveX = singleWidth * props.modelValue
  moveX.value = props.reverse ? newMoveX : newMoveX * -1
}
watch(() => props.modelValue, () => {
  setMoveX()
})

watch(() => isContainerVisible.value, async(visible) => {
  if (visible) {
    await nextTick()
    setMoveX()
  }
})

watch(() => props.isSmallStatus, () => {
  // 如果宽度是可变的 那么每次切换大小状态时 都需要重新计算 且由于是外界更新的宽度 所以需要下一次渲染后再计算
  !props.isFixedWidth && nextTick(setMoveX)
})

onMounted(() => {
  setMoveX()
})


</script>


<style lang="less" scoped>
.slide-content {
  width: 100%;
  display: flex;
  overflow: hidden;

  .slide-content-inner {
    display: flex;
  }
}
</style>
