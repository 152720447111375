<template>
  <div
    :class="{
      'ptn-blank__container': true,
      'ptn-blank__container-small': isSmallStatus,
    }"
  >
    <SwitchContainer
      :list="formatList"
      :origin-list="list?.[0]?.navs || []"
      :is-config-active="false"
      :shape="PIC_TOP_NAV_SHAPE.CIRCLE"
      :is-show-icon="false"
      :on-change="onChange"
    />
    <div
      v-show="formatList.length && !isSmallStatus"
      class="pictn-bottom"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LayoutBlank',
})
</script>

<script setup>
import { inject, computed, ref, getCurrentInstance } from 'vue'
import SwitchContainer from './slide-expand/SwitchContainer.vue'

import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { getPicTopNavAnalysisData } from 'public/src/pages/components/FilterBar/PicTopNav/utils/analysis.js'
import useToRealAndSelectPage from 'public/src/pages/components/FilterBar/PicTopNav/utils/useToRealAndSelectPage.js'
import { useRoute } from 'public/src/pages/components/FilterBar/hooks/useRouter.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { useSearchFilterWords } from 'public/src/pages/components/FilterBar/hooks/useVuex.js'
import {
  getIsToQuery,
  onChangeByToQuery
} from 'public/src/pages/components/FilterBar/PicTopNav/utils/toQuery.js'
import { handlePicTopNavParams, getTopNavigationSource, getFromPageCateId } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'

const list = inject('list', {})
const locals = inject('locals', {})
// const language = inject('language', {})
const isSmallStatus = inject('isSmallStatus', false)
const catInfo = inject('catInfo', {})
const route = useRoute()
const listAbtResult = inject('listAbtResult', {})
const tabIndex = ref(0)
const searchFilterWords = useSearchFilterWords()
const isUseSearch = inject('isUseSearch', false)
const currentInstance = getCurrentInstance()
const instance = computed(() =>
  Object.assign(currentInstance.proxy, {
    catInfo: catInfo.value,
    isUseSearch: isUseSearch.value,
  })
)

const isToQuery = computed(() =>
  getIsToQuery(unrefs({ catInfo, listAbtResult, isUseSearch }))
)

const formatList = computed(() => {
  const tabItem = list.value?.[0] || {}
  const { navs = [] } = tabItem

  return navs.map((navItem, navIndex) => ({
    id: navIndex,
    value: navItem.nav_id,
    label: navItem.nav_name,
    src: navItem.nav_img,
    lazyImg: locals.value.LAZY_IMG,
    isLazyLoad: navIndex > 5,
    analysisData: getPicTopNavAnalysisData({ tabItem, navItem, navIndex, catInfo, isUseSearch: isUseSearch.value, })
  }))
})
const toRealAndSelectPage = useToRealAndSelectPage({
  list,
  catInfo,
  extraParams: {
    pageName: catInfo.value.pageName,
  } })
const onChange = (navIndex, isSelect) => {
  if (isToQuery.value) {
    const params = handlePicTopNavParams(
      unrefs({
        list,
        tabIndex,
        navIndex,
        isSelect,
        businessReport: true,
        extraParams: {
          nav_to_query: isToQuery.value ? 1 : 0,
          pageName: catInfo.value.pageName,
          isUseSearch: isUseSearch.value,
        },
      })
    )

    if (['page_real_class', 'page_select_class'].includes(catInfo.value.pageName)) {
      params.from_page_cate_id = catInfo.value.from_page_cate_id || '-'
    }
    analysis.clickPicTopNav(params)

    const tabItem = list.value[tabIndex.value]
    const pic_tab_pos = tabIndex.value + 1
    // getTopNavigationSource 的入参取值
    const top_navigation_source = getTopNavigationSource({
      ...catInfo.value,
      ...params,
      // 列表跳搜索页时，埋点需要上报 from_page_cate_id
      ...(isUseSearch.value ? {
        from_page_cate_id: getFromPageCateId(catInfo.value),
      } : {})
    })
    onChangeByToQuery(
      unrefs({
        list,
        route,
        tabIndex,
        navIndex,
        searchFilterWords,
        instance,
        isSelect,
        extraQuery: {
          pic_tab_id: tabItem.tabId,
          pic_tab_type: tabItem.tabType,
          pic_tab_pos: isNaN(pic_tab_pos) ? '' : pic_tab_pos,
          top_navigation_source,
        },
        listAbtResult
      })
    )
    return
  }

  toRealAndSelectPage({ tabIndex: 0, navIndex })
}
</script>

<style lang="less" scoped>
.ptn-blank {
  &__container {
    padding: 8px 12px 0; // @flag-pic-padding 改之前搜一下这个标记
    background: #fff;
  }

  &__container-small {
    min-height: 1.17rem; // 设置同header高度，避免露出header底部分割线
    padding: 6px;
  }
}

.pictn-bottom {
  width: 100vw;
  margin-left: -12px; // @flag-pic-padding 改之前搜一下这个标记
  height: 6px;
  background: #f5f5f5;
  margin-top: 8px;
}
</style>
